
import React from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";
import axios from 'axios';
export const configJSON = require("../../framework/src/config.js");
import moment from "moment";
import { string } from "prop-types";
// Customizable Area Start
const urlConfig = require("../../framework/src/config");

// Customizable Area End


// export const configJSON = require("./config");
//admin
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  discussions: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  getListData: any;
  listMessage: string;
  getConversionData: any;
  selectedChat: number;
  chatTextBox: string;
  chatCamera: string;
  chatImage: string;
  showWebCam: boolean;
  imgSrc: any;
  conversionTotalPage: number;
  currentPage: number;
  rawConversionData: any;
  personName: string,
  claimId: string,
  showImagePopup: boolean,
  conversionID: any,
  onlineStatus: boolean,
  editDeleteIcon: boolean,
  profilePhoto: boolean,
  replyChatText: boolean,
  profileImage: any,
  replyIcon: any,
  editingItem: any,
  editedValue: string,
  editChatBox: boolean,
  scrollBar: boolean,
  profilePicture: any,
  replyChatMsg: any,
  replyChatMsgId: any,
  userProfilePic: any,
  userFullName: any,
  userDetailsPicture: any,
  userDetailsName: any,

  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminChatController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start

  // Customizable Area End
  public videoConstraints: any;
  public webcamRef: any;
  public capture: any;
  public videoFrameID: any;
  public conversionTimer: any;

  constructor(props: Props) {
    super(props);
    if (!localStorage.getItem('token')) {
      window.localStorage.href = '/login';
    }
    this.videoFrameID = document.querySelector("#video");
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      getListData: {},
      listMessage: '',
      getConversionData: [],
      selectedChat: 0,
      chatTextBox: '',
      chatCamera: '',
      chatImage: '',
      showWebCam: false,
      imgSrc: null,
      conversionTotalPage: 0,
      currentPage: 1,
      rawConversionData: [],
      personName: '',
      claimId: '',
      showImagePopup: false,
      conversionID: 0,
      onlineStatus: false,
      editDeleteIcon: false,
      profilePhoto: false,
      replyChatText: false,
      profileImage: '',
      replyIcon: null,
      editingItem: null,
      editedValue: '',
      editChatBox: false,
      scrollBar: false,
      replyChatMsg: '',
      replyChatMsgId: '',
      profilePicture: '/images/blue-image.png',
      userProfilePic: null,
      userFullName: string,
      userDetailsPicture: null,
      userDetailsName: null


      // Customizable Area Start
      // Customizable Area End
    };


  }
  chatAreaRef = React.createRef<HTMLDivElement>();

  getChatList() {
    const baseUrl = urlConfig.baseURL;
    
    const params = {
      headers: {
        "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": '*',
        "token": localStorage.getItem('token')
      }
    };
    axios.get(`${baseUrl}/bx_block_livechat/conversation_listing?page=1&per_page=10000000`, params)
      .then(res => {
        if (res?.status === 200) {
          this.setState({
            getListData: res.data
          });
         
        } else {
        }
      })
      .catch(error => {
      });
  }

  getConversion(id: any, page: number, isAppend = false, scrollBottom = true) {
    
    const baseUrl = urlConfig.baseURL;
    
    this.readAll(id);
    this.userStatus(id);
    const params = {
      headers: {
        "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": '*',
        "token": localStorage.getItem('token')
      }
    };
    if (page === 1) {
      this.setState({
        selectedChat: id
      });
    }
    this.setState({
      conversionID: id
    });
    axios.get(`${baseUrl}/bx_block_livechat/admin_conversation?conversation_id=${id}&order=desc&page=${page}&per_page=500`, params)
      .then(res => {

        window.scrollTo(0, 900);
     
        if (res?.status === 200) {
          if (page === 1) {

            this.setState({
              personName: res?.data?.data.length > 0 ? res?.data?.data[0]?.attributes?.user_full_name : '',
              claimId: res?.data?.data.length > 0 ? res?.data?.data[0]?.attributes?.claim_reference_number : '',
            });
          }
          let apiData = [];
          if (isAppend === true) {
            const previousData = this.state.rawConversionData;
            apiData = [...previousData, ...res?.data?.data]
            this.setState({
              rawConversionData: apiData
            });

          } else {
            this.setState({
              currentPage: 1
            });
            apiData = res?.data?.data;
            this.setState({
              rawConversionData: res?.data?.data
            });
          }

          const filterData = this.modifyData(apiData);
          this.setState({
            getConversionData: Array.from(filterData).reduceRight((acc: any, item) => {
              acc.push(item);
              return acc;
            }, [])

          });
          this.chatAreaRef.current?.scrollIntoView();
          this.setState({
            conversionTotalPage: res?.data?.meta?.total_result
          });

        }

        else {
          this.setState({
            listMessage: res.data
          })
        }
      })
      .then(ress => {
        this.setState({ userProfilePic: this.state.userDetailsPicture })
        this.setState({ userFullName: this.state.userDetailsName })
      })
      .catch(error => {
      });
  }
  handleProfileImage(img: any) {
    this.setState({ userDetailsPicture: img })

  }


  handleSelectUser(id: any, img: any, userName: any) {
    this.getChatList()
    this.getConversion(id, 1)
    this.handleProfileImage(img)
    this.setState({ userDetailsName: userName })
  }

  uploadChatImage(e: any, id: any) {
    e.preventDefault();
    const fileData = e.target.files[0];
    if (fileData !== undefined) {
      this.setState({
        chatImage: fileData.name
      })
      let chatData = new FormData();
      chatData.append('content', this.state.chatTextBox);
      chatData.append('photo', fileData);
      chatData.append('conversation_id', id);
      this.sendChat(id, chatData);

    }
  }
  updateChat(index: any, dataChat: any) {
    this.setState({ editChatBox: index })
    this.setState({ editingItem: index });
    this.setState({ editedValue: dataChat })
  }

  handleSaveItem = (conversionID: any, messageID: any, dataChat: any) => {
    this.setState({ editChatBox: false })

    const baseUrl = urlConfig.baseURL;
    let formdata = new FormData();
    formdata.append("conversation_id", conversionID)
    formdata.append("message_id", messageID)
    formdata.append("content", dataChat)
    const params = {
      headers: {
        "Content-Type": 'multipart/form-data',
        "Access-Control-Allow-Origin": '*',
        "token": localStorage.getItem('token')
      }
    };
    axios.put(`${baseUrl}/bx_block_livechat/edit_admin_chat_message`, formdata, params)
      .then(res => {
        this.getConversion(conversionID, 1)

      })
      .catch(error => {

      });
  };
  handleSendReply(e: any, id: any) {
    this.setState({ replyChatText: false })
    e.preventDefault();
    if (this.state.chatTextBox) {
      let chatData = new FormData();
      chatData.append('content', this.state.chatTextBox);
      chatData.append('conversation_id', id);
      chatData.append('reply_to_message_id', this.state.replyChatMsgId)
      this.sendChat(id, chatData);
    }
  }


  SendTextChat(e: any, id: any) {
    this.setState({ scrollBar: false })
    this.chatAreaRef.current?.scrollIntoView();
    e.preventDefault();
    if (this.state.chatTextBox) {
      let chatData = new FormData();
      chatData.append('content', this.state.chatTextBox);
      chatData.append('conversation_id', id);
      this.sendChat(id, chatData);
    }
  }

  handleEditItem = (index: any) => {
    this.setState({ editingItem: index });

  };

  sendChat(id: any, chatData: any) {
    const baseUrl = urlConfig.baseURL;
    const params = {
      headers: {
        // "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": '*',
        "token": localStorage.getItem('token'),
      }
    };
    axios.post(`${baseUrl}/bx_block_livechat/admin_reply`, chatData, params)
      .then(res => {
        this.getConversion(id, 1);
        this.setState({ chatTextBox: '' });
      })
      .catch(error => {
        this.setState({
          listMessage: error.res.data
        })
      });
  }

  async componentDidMount() {
    super.componentDidMount();
  }
  componentWillMount() {
    this.timer();
    this.refreshChat();
  }

  async componentWillUnmount() {
  }

  refreshChat() {
    this.conversionTimer = setInterval(() => {
      if (this.state.conversionID && this.state.conversionID !== 0) {
        this.getConversion(this.state.conversionID, 1, false, true);
      }
    }, 10000);
  }

  loadMore() {
    if (window.innerHeight + document.documentElement.scrollTop === document?.scrollingElement?.scrollHeight) {
    }
  }

  handelInput = (e: any) => {
    this.setState({
      chatTextBox: e.target.value
    });
  };
  // Customizable Area End

  // Camera Functions


  retake = () => {
    this.setState({
      showWebCam: true
    });
    this.setState({
      imgSrc: null
    });
  }
  closeCamera = () => {
    this.setState({
      showWebCam: false
    });
    this.setState({
      imgSrc: null
    });
  }
  //Camera Functions

  showBigImage(src: string) {
    if (src) {
      this.setState({
        showImagePopup: true
      });
      this.setState({
        chatImage: src
      });
    }
  }
  closeImagePopup() {
    this.setState({
      showImagePopup: false
    });
  }
  getExtension(filename: string) {
    return filename?.toLowerCase().toString().split('.').pop()
  }
  handleScroll(e: any) {
    if (e.currentTarget.scrollTop === 0) {
      const pages = Math.ceil(this.state.conversionTotalPage / 500);
      if (pages > 1 && this.state.currentPage <= pages) {
        const currentPageValue = this.state.currentPage;
        const nextPage = currentPageValue + 1;
        this.setState({
          currentPage: nextPage
        });
        this.getConversion(e.target.id, nextPage, true, false)
      }
    }
  }

  modifyData(data: any) {
    const groups = data?.reduce((groups: any, chat: any) => {
      const date = moment(chat.attributes?.created_at).format("D MMMM YYYY");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(chat);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        chat: groups[date]
      };
    });
    return groupArrays;
  }

  timer = () => {
    setInterval(() => {
      this.getChatList();
      this.userStatus(this.state.conversionID);
    }, 1000)
  }
  readAll(conversionID: any) {

    const baseUrl = urlConfig.baseURL;
    const readAllData = new FormData();
    readAllData.append('conversation_id', conversionID)
    readAllData.append('is_read', "true")
    const params = {
      headers: {
        // "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": '*',
        "token": localStorage.getItem('token')
      }
    };

    axios.post(`${baseUrl}/bx_block_livechat/is_read`, readAllData, params)
      .then(res => {

      })
      .catch(error => {
      });
  }

  deleteChat(conversionID: number, chatID: number) {
    const baseUrl = urlConfig.baseURL;
    const params = {
      headers: {
        // "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": '*',
        "token": localStorage.getItem('token')
      }

    };

    axios.delete(`${baseUrl}/bx_block_livechat/delete_chat_attachment?conversation_id=${conversionID}&chat_id=${chatID}`, params)
      .then(res => {
        this.getConversion(conversionID, 1);

      })
      .catch(error => {

      });
  }

  replyChat(id: number, chatMsg: any) {
    this.setState({ replyChatText: true })
    this.setState({ replyChatMsg: chatMsg })
    this.setState({ replyChatMsgId: id })
  }
  showReplyIcon(list: any) {

    this.setState({ replyIcon: list });

  }
  confirm(data: any) {
    const result = confirm("Delete the items?")
    if (result) {
      this.deleteChat(data?.attributes?.conversation_id, data?.attributes?.id)
    }

  }
  uploadProfileImage = async (e: any) => {
    e.preventDefault();
    const fileData = e.target.files[0];

    const tokens = await localStorage.getItem('token')
    let formData = new FormData();
    formData.append('profile_picture', fileData);
    if (tokens != null) {
      formData.append("token", tokens)
    }
    const baseUrl = urlConfig.baseURL;
    const params = {
      headers: {
        "Content-Type": 'multipart/form-data',
        "Access-Control-Allow-Origin": '*',
        "token": localStorage.getItem('token'),
      }
    };
    axios.post(`${baseUrl}/bx_block_admin/admin_login/upload_admin_profile_picture`, formData, params)
      .then(res => {

        this.setState({ profilePhoto: false })
        this.setState({ profilePicture: res?.data?.data?.attributes?.profile_picture });
      })
      .catch(error => {
        this.setState({
          listMessage: error.res.data
        })
      });

  }

  editDelete() {
    this.setState({ editDeleteIcon: !this.state.editDeleteIcon })
  }
  profilePage() {
    this.setState({ profilePhoto: !this.state.profilePhoto });
  }
  userStatus(conversionID: any) {
    const baseUrl = urlConfig.baseURL;
    if (conversionID && conversionID !== 0) {
      const params = {
        headers: {
          // "Content-Type": 'application/json',
          "Access-Control-Allow-Origin": '*',
          "token": localStorage.getItem('token')
        }
      };
      axios.get(`${baseUrl}/account_block/user_status?conversation_id=${conversionID}`, params)
        .then(res => {
          if (res?.data?.account === 'active') {
            
            this.setState({
              onlineStatus: true
            });
          } else {
            this.setState({
              onlineStatus: false
            });
          }

        })
        .catch(error => {
        });
    }
  }

}
