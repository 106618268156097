import React, { Component } from "react";
// import AlertBlock from "../../../alert/src/AlertBlock";
import './style.css';
import AdminChatController, { Props } from './AdminChatController';
import moment from "moment";
// import { reverse } from "dns";

class AdminChat extends AdminChatController {
  static instance: AdminChat;
  constructor(props: Props) {
    super(props);
    AdminChat.instance = this;
    this.getChatList();
    if (!localStorage.getItem('token') || localStorage.getItem('token') === '') {
      window.location.href = '/login';
    }
  }

  async componentDidMount(): Promise<void> {
  }


  render() {
    const { getListData, getConversionData, selectedChat, chatTextBox, editChatBox, profilePicture } = this.state

    return (
      <>
        <div className='chat'>
          <div className='box-1'>
            <div className='icons-box active' onClick={() => this.profilePage()}>

              {
                profilePicture ? <img className='icons' src={profilePicture} /> : ""
              }
            </div>
            <div style={{ marginBottom: "280px" }} className='icons-box active2'>
              <img className='icons' src='/images/blue-comment.png' />
            </div>

          </div>
          {this.state.profilePhoto ? <div className="user-profile-page">
            <div className="profile">Profile Photo</div>
            <div className="box">
              <label> <img src={require("./addprofile.png")} alt="profile" /></label>
              <input type="file" name='image' onChange={(e) => this.uploadProfileImage(e)} className="inputfile" accept="application/pdf,image/*" />
            </div>
          </div> : " "}
          <div className='box-2'>
            <div className='filters'>
              <div className='all'></div>
              <div className='filter-link'>
                <a href="/logout">Logout</a>
              </div>
            </div>
            <div className="chat-box first p-0"></div>
            {getListData?.data?.length > 0 && getListData?.data?.map((result: any) => {
              return (
                <div className={selectedChat === result?.attributes?.id ? 'chat-box active' : 'chat-box'} onClick={() => {
                  this.handleSelectUser(result?.attributes?.id, result?.attributes?.user_profile_image, result?.attributes?.full_name
                  )
                }}>
                  <img src={result?.attributes?.user_profile_image ? result?.attributes?.user_profile_image : require("./profile.webp")} className='profile' alt="profile" />
                  <div className='profile-box'>
                    <h6 className='person-name'>{result?.attributes?.full_name}</h6>
                    {result?.attributes?.message_caption !== null && <h6 className='message-caption'>
                      {result?.attributes?.message_caption?.is_read === true ? result?.attributes?.message_caption.content : <strong style={{ color: '#333' }}>{result?.attributes?.message_caption.content}</strong>}</h6>}
                  </div>
                  <div className='id-box'>
                    {result?.attributes?.claim_reference_numbers.length > 0 ? <h6 className='claim'>Claim ID: #{result?.attributes?.claim_reference_numbers[0]}</h6> : ""}
                    {result?.attributes?.unreade_message_count ? <div className="showCount">{result?.attributes?.unreade_message_count}</div> : ""}
                  </div>
                </div>
              )
            })
            }
          </div>
          <div className='box-3'>
            {getConversionData?.length === 0 &&
              <div className='empty-chat'>
                <h5>Chat</h5>
                <p>click on any item to send and receive message</p>
              </div>
            }
            {getConversionData?.length > 0 && <>
              <div className='person-details'>
                <div>
                  <img src={this.state.userProfilePic ? this.state.userProfilePic : require('./profile.webp')}
                    className='person-profile' alt="profile" style={{ borderRadius: "50%" }} />

                  <span className='person-name'>{this.state.userFullName}</span>
                  <span className='status'>{this.state.onlineStatus === true ? <><span className='green-dot'></span>Online</> : <><span className='red-dot'></span>Offline</>}</span>
                </div>
                <div style={{ marginRight: '-400px', color: "#56c6fa", fontWeight: "bold" }}>
                  Claim
                </div>
                <div className='d-flex align-items-center'>
                  {this.state.claimId && <span className='link'>Claim: {this.state.claimId}</span>}

                </div>
              </div>
              <div className='chat-message' id={getConversionData?.length > 0 && getConversionData[0]?.chat[0]?.attributes?.conversation_id} onScroll={(e) => this.handleScroll(e)}>
                <div></div>
                <div className='messages-box'>
                  {getConversionData?.length > 0 && getConversionData?.map((result: any) => {
                    return (
                      <>
                        <div className='days'>{result.date}</div>
                        {result?.chat.length > 0 && result?.chat?.reduceRight((acc: any, item: any) => [...acc, item], []).map((data: any, index: any) => {
                          return (
                            <>
                              {data?.attributes?.sender_type === 'AccountBlock::Account' && <div className='sender'>
                                <img style={{ borderRadius: "50%" }} src={data?.attributes?.user_profile_image ? data?.attributes?.user_profile_image : require("./profile.webp")} className='profile-pic' alt="profile" />
                                <div className='sender-message'>
                                  <div className='message' id={data?.attributes?.id}>
                                    {data?.attributes?.photo &&
                                      <>
                                        {(this.getExtension(data?.attributes?.photo) === 'png' || this.getExtension(data?.attributes?.photo) === 'jpeg' || this.getExtension(data?.attributes?.photo) === 'jpg') ? <div onClick={() => { this.showBigImage(data?.attributes?.photo) }}>
                                          <button type="button" className='modalbutton' data-bs-toggle="modal" data-bs-target="#showImage">
                                            <img className='attachment' src={data?.attributes?.photo} alt="attachment" />
                                          </button>
                                        </div> : <a href={data?.attributes?.photo} target="_BLANK">
                                          {
                                            (this.getExtension(data?.attributes?.photo) === 'pdf') ? <img src={'/images/pdf.png'} alt={'pdf'} /> :
                                              (this.getExtension(data?.attributes?.photo) === 'doc' || this.getExtension(data?.attributes?.photo) === 'docx') ? <img src={'/images/docx.png'} alt={'docx'} /> : (this.getExtension(data?.attributes?.photo) === 'xls' || this.getExtension(data?.attributes?.photo) === 'xlsx') ? <img src={'/images/xls.png'} alt={'xls'} /> : <img src={'/images/file.png'} alt={'file'} />
                                          }
                                        </a>
                                        }
                                      </>
                                    }
                                    {data?.attributes?.content && <><span key={data} onMouseEnter={() => this.showReplyIcon(index)} onMouseLeave={() => this.setState({ replyIcon: null })}>{data?.attributes?.content}{this.state.replyIcon === index && (<img src={require("./reply.png")} id="showIcon" onClick={() => this.replyChat(data?.attributes?.id, data?.attributes?.content)} />)}</span></>}
                                  </div>
                                  <p className='sms-time'>{moment(data?.attributes?.created_at).format("hh:mm A")}</p>
                                </div>
                              </div>
                              }
                              {data?.attributes.sender_type === 'AdminUser' && <div className='reciever' key={index}>
                                <div className='reciever-message'>
                                  <div className='message'>
                                    {data?.attributes?.photo &&
                                      <>
                                        {(this.getExtension(data?.attributes?.photo) === 'png' || this.getExtension(data?.attributes?.photo) === 'jpeg' || this.getExtension(data?.attributes?.photo) === 'jpg') ? <div className="delete-file"><div onClick={() => { this.showBigImage(data?.attributes?.photo) }}>
                                          <button type="button" className='modalbutton' data-bs-toggle="modal" data-bs-target="#showImage">
                                            <img className='attachment' src={data?.attributes?.photo} alt="attachment" />
                                          </button>
                                        </div><button className='trash' onClick={() => { this.confirm(data) }}><img src='/images/trash.png' alt="delete" /></button></div> : <div className="delete-file"><a href={data?.attributes?.photo} target="_BLANK">
                                          {
                                            (this.getExtension(data?.attributes?.photo) === 'pdf') ? <img src={'/images/pdf.png'} alt={'pdf'} /> :
                                              (this.getExtension(data?.attributes?.photo) === 'doc' || this.getExtension(data?.attributes?.photo) === 'docx') ? <img src={'/images/doc.png'} alt={'doc'} /> : (this.getExtension(data?.attributes?.photo) === 'xls' || this.getExtension(data?.attributes?.photo) === 'xlsx') ? <img src={'/images/xls.png'} alt={'xls'} /> : <img src={'/images/file.png'} alt={'file'} />
                                          }
                                        </a>
                                          <button className='trash' onClick={() => { this.confirm(data) }}>
                                            <img src='/images/trash.png' alt="delete" />
                                          </button>
                                        </div>
                                        }
                                      </>
                                    }
                                    <div className={data?.attributes?.reply_to_message_id ? "replyChatMsgs" : ""}>
                                      {data?.attributes?.reply_to_message_id ? <div>{data?.attributes?.reply_to_message?.data?.attributes?.content}</div> : ""}

                                      {data?.attributes?.content && <><span key={data} onMouseOver={() => this.showReplyIcon(index)}>{data?.attributes?.content}</span> </>}

                                    </div></div>
                                  <p className='sms-time'>{moment(data?.attributes?.created_at).format("hh:mm A")}</p>
                                </div>
                                <img src='/images/profile.png' className='profile-pic' alt="profile" />
                                {data?.attributes?.content && this.state.replyIcon === index &&
                                  <img src={require("./showIcons.png")} onClick={() => { this.editDelete() }} className="icons" id="showIcons" alt="" />

                                }
                                {data?.attributes?.content && this.state.replyIcon === index && this.state.editDeleteIcon ? <><img src={require("./delete.png")} className="trash" id="showIcon" onClick={() => { this.confirm(data) }} /><img src={require("./edit.png")} id="showIcon" onClick={() => this.updateChat(index, data?.attributes?.content)} /></> : ""
                                }
                                {this.state.editChatBox === index ? <div className="editBox">
                                  <div className="editBoxHeading">
                                    <div>Edit Message</div><div onClick={() => this.setState({ editChatBox: false })}> X </div>
                                  </div>
                                  <div className="editBoxContent">
                                    {this.state.editingItem === index ?
                                      <input
                                        className="editingBox"
                                        type="text"
                                        value={this.state.editedValue}
                                        onChange={(e) => this.setState({ editedValue: e.target.value })}
                                      />

                                      : ""}

                                  </div >
                                  <div className="editChatChanges" >
                                    <button style={{ border: "none" }} onClick={() => this.setState({ editChatBox: false })} >Cancel</button>
                                    <button className="editSave" onClick={() => this.handleSaveItem(data?.attributes?.conversation_id, data?.attributes?.id, this.state.editedValue)}>Save</button>
                                  </div>
                                </div>

                                  : " "}
                              </div>
                              }
                              <div className="modal" id="showImage">
                                <div className="modal-dialog modal-fullscreen modal-dialog-centered">
                                  <button type="button" className="close-btn" data-bs-dismiss="modal" onClick={() => { this.closeImagePopup() }}>&times;</button>
                                  <img className='modal-image' src={this.state.chatImage} alt="attachment" />
                                </div>
                              </div>
                            </>
                          )
                        })
                        }
                      </>
                    )
                  })
                  }
                </div>

                <div ref={this.chatAreaRef}></div>
              </div>
              <form onSubmit={this.state.replyChatText ? (e) => { this.handleSendReply(e, getConversionData[0]?.chat[0]?.attributes?.conversation_id) } : (e) => { this.SendTextChat(e, getConversionData[0]?.chat[0]?.attributes?.conversation_id) }}>
                <div className='message-input-box' style={{ height: "60px" }}>
                  <div className="replyMessage">
                    <div>{this.state.replyChatText && <div className="replyBack"><div>{this.state.replyChatMsg}</div><div onClick={() => this.setState({ replyChatText: false })} style={{ cursor: "pointer" }}>x</div></div>} </div>
                    <input type='text' className='message-input' placeholder='Type Something' value={chatTextBox} onChange={this.handelInput}  />
                  </div>
                  <div>
                    <div className="box">

                      <img src={require("./upload-removebg-preview.png")} className="icons" id="uploadIcon" alt="profile" />
                      <input type="file" name='image' accept="application/pdf,image/*" onChange={(e) => { this.uploadChatImage(e, getConversionData[0]?.chat[0]?.attributes?.conversation_id) }} className="inputfile" />
                    </div>
                    <div className="box">

                      <img src={require("./attachment.png")} className="icons" id="uploadIcon" alt="profile" style={{ fontWeight: "bold", width: '20px', height: '20px', marginLeft: "10px" }} />
                      <input type="file" name='image' accept=".doc,.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword" onChange={(e) => { this.uploadChatImage(e, getConversionData[0]?.chat[0]?.attributes?.conversation_id) }} className="inputfile" />
                    </div>
                  </div>
                </div>
              </form>
            </>
            }
          </div>
        </div>

      </>
    );
  }
}


// // Customizable Area End
export default AdminChat;


